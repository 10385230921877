import { isPlatformBrowser } from '@angular/common';
import { PLATFORM_ID, Pipe, PipeTransform, inject } from '@angular/core';
import { environment } from '@env/environment';

@Pipe({
  name: 'brokenImageHandler',
  standalone: true
})
export class BrokenImageHandlerPipe implements PipeTransform {

  private readonly deployUrl = environment.deployUrl;

  private platformId = inject(PLATFORM_ID);
  #isBrowser = false;

  constructor() {
    this.#isBrowser = isPlatformBrowser(this.platformId);
  }

  transform(value: string): unknown {
    return this.#isBrowser ? value : `${this.deployUrl}/assets/images/fallback.webp`
  }
}
